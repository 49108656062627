import Vue from 'vue'
import VueRouter from 'vue-router'
import App from '../views'
import AuthRequired from '../utils/AuthRequired'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'App',
    component: App,
    beforeEnter: AuthRequired,
    redirect: '/home',
    children: [    
      {
        path: 'home',
        name: 'Home',
        component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
      },
      {
        path: 'profile',
        name: 'Profile',
        component: () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue')
      },
      {
        path: 'transaksi',
        name: 'Transaksi',
        component: () => import(/* webpackChunkName: "transaksi" */ '../views/Transaksi'),
        redirect: '/transaksi/dashboard',
        children: [    
          {
            path: 'setoran',
            name: 'Setoran',
            component: () => import(/* webpackChunkName: "setoran" */ '../views/Transaksi/Setoran.vue')
          },
          {
            path: 'setoran-form/:cm_code?/:cif_no?',
            name: 'SetoranForm',
            component: () => import(/* webpackChunkName: "setoran-form" */ '../views/Transaksi/SetoranForm.vue')
          },
        ]
      },
      {
        path: 'anggota/:cm_code?',
        name: 'Anggota',
        component: () => import(/* webpackChunkName: "anggota" */ '../views/Anggota.vue')
      },
      {
        path: 'keanggotaan',
        name: 'Keanggotaan',
        component: () => import(/* webpackChunkName: "keanggotaan" */ '../views/Keanggotaan'),
        redirect: '/keanggotaan/dashboard',
        children: [    
          {
            path: 'dashboard',
            name: 'AnggotaDashboard',
            component: () => import(/* webpackChunkName: "anggotaDashboard" */ '../views/Keanggotaan/Dashboard.vue')
          },
          {
            path: 'masuk',
            name: 'AnggotaMasuk',
            component: () => import(/* webpackChunkName: "anggotaMasuk" */ '../views/Keanggotaan/Masuk.vue')
          },
          {
            path: 'keluar',
            name: 'AnggotaKeluar',
            component: () => import(/* webpackChunkName: "anggotaKeluar" */ '../views/Keanggotaan/Keluar.vue')
          }
        ]
      },
      {
        path: 'pembiayaan',
        name: 'Pembiayaan',
        component: () => import(/* webpackChunkName: "pembiayaan" */ '../views/Pembiayaan'),
        redirect: '/pembiayaan/dashboard',
        children: [    
          {
            path: 'dashboard',
            name: 'PembiayaanDashboard',
            component: () => import(/* webpackChunkName: "pembiayaanDashboard" */ '../views/Pembiayaan/Dashboard.vue')
          },
          {
            path: 'masuk',
            name: 'PembiayaanMasuk',
            component: () => import(/* webpackChunkName: "pembiayaanMasuk" */ '../views/Pembiayaan/Masuk.vue')
          },
          {
            path: 'masuk/:map_no?/:cif_no?/:cm_code?',
            name: 'PembiayaanMasukMapNo',
            component: () => import(/* webpackChunkName: "pembiayaanMasuk" */ '../views/Pembiayaan/Masuk.vue')
          },
          {
            path: 'keluar',
            name: 'PembiayaanKeluar',
            component: () => import(/* webpackChunkName: "pembiayaanKeluar" */ '../views/Pembiayaan/Keluar.vue')
          },
          {
            path: 'daftar',
            name: 'PembiayaanDaftar',
            component: () => import(/* webpackChunkName: "pembiayaanDaftar" */ '../views/Pembiayaan/Daftar.vue')
          }
        ]
      },
      {
        path: 'tabungan',
        name: 'Tabungan',
        component: () => import(/* webpackChunkName: "tabungan" */ '../views/Tabungan'),
        redirect: '/tabungan/dashboard',
        children: [    
          {
            path: 'dashboard',
            name: 'TabunganDashboard',
            component: () => import(/* webpackChunkName: "tabunganDashboard" */ '../views/Tabungan/Dashboard.vue')
          },
          {
            path: 'masuk',
            name: 'TabunganMasuk',
            component: () => import(/* webpackChunkName: "tabunganMasuk" */ '../views/Tabungan/Masuk.vue')
          },
          {
            path: 'keluar',
            name: 'TabunganKeluar',
            component: () => import(/* webpackChunkName: "tabunganKeluar" */ '../views/Tabungan/Keluar.vue')
          },
          {
            path: 'perpanjang',
            name: 'TabunganPerpanjang',
            component: () => import(/* webpackChunkName: "tabunganPerpanjang" */ '../views/Tabungan/Perpanjang.vue')
          }
        ]
      },
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/forgot',
    name: 'Forgot',
    component: () => import(/* webpackChunkName: "forgot" */ '../views/Forgot.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
