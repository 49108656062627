<template>
  <div class="orange lighten-1 px-5 py-3 justify-center align-items-center bt-navigation" :class="$route.path == '/login' ? 'd-none' : 'd-flex'">
    <router-link to="/home" class="d-flex justify-center align-center rounded-lg pa-1 mx-4" :class="($route.path == '/home') ? 'orange darken-1' : 'orange lighten-5 elevation-3'">
      <v-icon :color="($route.path == '/home') ? 'orange lighten-5' : 'orange darken-1'">
        mdi-home
      </v-icon>
    </router-link>
    <router-link to="/keanggotaan" class="d-flex justify-center align-center rounded-lg pa-1 mx-4" :class="($route.path == '/keanggotaan') ? 'orange darken-1' : 'orange lighten-5 elevation-3'">
      <v-icon :color="($route.path == '/keanggotaan') ? 'orange lighten-5' : 'orange darken-1'">
        mdi-account-group
      </v-icon>
    </router-link>
    <router-link to="/pembiayaan" class="d-flex justify-center align-center rounded-lg pa-1 mx-4" :class="($route.path == '/pembiayaan') ? 'orange darken-1' : 'orange lighten-5 elevation-3'">
      <v-icon :color="($route.path == '/pembiayaan') ? 'orange lighten-5' : 'orange darken-1'">
        mdi-cash-multiple
      </v-icon>
    </router-link>
    <router-link to="/tabungan" class="d-flex justify-center align-center rounded-lg pa-1 mx-4" :class="($route.path == '/tabungan') ? 'orange darken-1' : 'orange lighten-5 elevation-3'">
      <v-icon :color="($route.path == '/tabungan') ? 'orange lighten-5' : 'orange darken-1'">
        mdi-wallet
      </v-icon>
    </router-link>
  </div>
</template>
<script>
export default {
  mounted(){
    
  }
}
</script>