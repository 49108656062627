import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: localStorage.getItem('baikTpl') != null ? JSON.parse(localStorage.getItem('baikTpl')) : [],
    transaksi: localStorage.getItem('baikTplTransaksi') != null ? JSON.parse(localStorage.getItem('baikTplTransaksi')) : [],
    pembiayaan: localStorage.getItem('baikTplPembiayaan') != null ? JSON.parse(localStorage.getItem('baikTplPembiayaan')) : [],
    anggota: localStorage.getItem('baikTplAnggota') != null ? JSON.parse(localStorage.getItem('baikTplAnggota')) : [],
    tabungan: localStorage.getItem('baikTplTabungan') != null ? JSON.parse(localStorage.getItem('baikTplTabungan')) : [],
    kas: localStorage.getItem('baikTplKas') != null ? JSON.parse(localStorage.getItem('baikTplKas')) : [],
    rembug: localStorage.getItem('baikTplRembug') != null ? JSON.parse(localStorage.getItem('baikTplRembug')) : [],
    anggota: localStorage.getItem('baikTplAnggota') != null ? JSON.parse(localStorage.getItem('baikTplAnggota')) : [],
  },
  getters: {
    user: state => state.user,
    transaksi: state => state.transaksi,
    pembiayaan: state => state.pembiayaan,
    anggota: state => state.anggota,
    tabungan: state => state.tabungan,
    kas: state => state.kas,
    rembug: state => state.rembug,
    anggota: state => state.anggota,
  },
  mutations: {
    setUser(state,payload){
      state.user = payload
      localStorage.setItem('baikTpl', JSON.stringify(state.user))
    },
    removeUser(state){
      state.user = []
      localStorage.removeItem('baikTpl')
    },
    setTransaksi(state, payload){
      let transaksi = payload
      state.transaksi = transaksi
      localStorage.setItem('baikTplTransaksi',JSON.stringify(transaksi))
    },
    setPembiayaan(state, payload){
      let pembiayaan = payload
      state.pembiayaan = pembiayaan
      localStorage.setItem('baikTplPembiayaan',JSON.stringify(pembiayaan))
    },
    setAnggota(state, payload){
      let anggota = payload
      state.anggota = anggota
      localStorage.setItem('baikTplAnggota',JSON.stringify(anggota))
    },
    setTabungan(state, payload){
      let tabungan = payload
      state.tabungan = tabungan
      localStorage.setItem('baikTplTabungan',JSON.stringify(tabungan))
    },
    setKas(state, payload){
      let kas = payload
      state.kas = kas
      localStorage.setItem('baikTplKas',JSON.stringify(kas))
    },
    setRembug(state, payload){
      let rembug = payload
      state.rembug = rembug
      localStorage.setItem('baikTplRembug',JSON.stringify(rembug))
    },
    setAnggota(state, payload){
      let anggota = payload
      state.anggota = anggota
      localStorage.setItem('baikTplAnggota',JSON.stringify(anggota))
    }
  },
  actions: {
    login({commit},payload) {
      commit('setUser',payload)
    },
    logout({commit}) {
      commit('removeUser')
    },
    saveTransaksi({commit, state}, payload) {

    },
    savePembiayaan({commit, state}, payload) {
      let pembiayaan = {...state.pembiayaan}
      if(pembiayaan.length > 0){
        let fPembiayaan = pembiayaan.find((item) => item.map_no == payload.map_no)
        console.log(fPembiayaan)
      } else {
        pembiayaan = []
        pembiayaan.push(payload)
      }
      commit('setPembiayaan',pembiayaan)
    },
    saveAnggota({commit, state}, payload) {

    },
    saveTabungan({commit, state}, payload) {

    },
    saveRembug({commit, state}, payload) {
      commit('setRembug',payload)
    },
    saveAnggota({commit, state}, payload) {

    },
  },
  modules: {
  }
})
